jQuery(function ($) {
    $(document).ready(function () {
        
        $(window).load(function () {
            adjustPadding();
        });

        $( window ).resize(function() {
            adjustPadding();
          });

        function adjustPadding() {
            $('.def6-block.arrow-image .content-block').each(function(){
                let height = $(this).find('.image-col').height();

                $(this).find('.image-col .content-image').css('padding-bottom', height);
            });
        }
    });
});